.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;

    .videodesk {
        position: absolute;
        object-fit: cover;
        right: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        z-index: -1;
    }

    .videomob {
        display: none;
    }

    h1 {
        font-weight: 900;
        font-size: 80px;
        width: 1045px;
        margin-bottom: 20px;
        text-align: center;
        color: white;
        margin: 4rem 0 0 0;
    }

    h2 {
        font-size: 68px;
        font-weight: 400;
        width: 650px;
        text-align: center;
        color: white;
        margin: 0;
    }

    h3{
        font-size: 23px;
        font-weight: 400;        
        text-align: center;
        color: white;        
    }

    button {
        color: #0667B0;
        background-color: rgba(255, 255, 255, 0.87);
        border-radius: 38px;
        border: 2px solid #0667B0;
        padding: 10px 50px;
        font-size: 20px;
        font-weight: 600;
        margin-top: 2rem;
    }

    .home-button {
        margin-top: 6rem;
    }

    .home-whatsapp {
        position: relative;
    }

    @media screen and (min-width: 300px) and (max-width:1099px) {
        height: 100vh;
        margin: 0;
        padding: 0;

        .videodesk {
            display: block;
        }

        .videomob {
            display: none;
        }

        h1 {
            font-size: 42px;
            width: 370px;
            line-height: 100%;
            margin-top: 7rem;
        }

        h2 {
            font-size: 45px;
            width: 325px;
            color: white;
            margin-top: 1rem;
            font-weight: 400;
        }

        h3{
            padding: 0 50px;      
        }

        button {
            margin-top: 4rem;
            padding: 10px 50px;
            font-size: 20px;
        }

        .home-button {
            display: none;
        }
    }
}