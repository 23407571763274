.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 146px;
    background: linear-gradient(180deg, #0667B0 0%, rgba(6, 103, 176, 0.520833) 66.67%, rgba(6, 103, 176, 0) 100%);

    .navbar-mobile {
        display: none;

        svg {
            color: white;
        }
    }

    .navbar-menu {
        display: none;
    }


    .navbar-logo {
        width: 80px;
        height: 86px;

        img {
            width: 68px;
            margin-left: 2rem;
        }
    }

    .navbar-list {
        display: flex;
        justify-content: space-between;
        margin: 0 0 0 0rem;

        p {
            margin: 0 2.5rem 0 2.5rem;
            font-weight: 700;
            color: white;
            font-size: 18px;
            cursor: pointer;
        }
    }

    .navbar-button {

        button {
            color: #03ff95;
            background-color: transparent;
            border-radius: 38px;
            border: 2px solid #03ff95;
            font-size: 18px;
            font-weight: 600;
            margin: 0 1rem 0.2rem 0;
            align-items: center;
        }
    }

    .navbar-language {
        color: white;
        display: flex;
        margin-right: 2rem;

        p {
            margin-right: 0.5rem;
            font-size: 18px;
            cursor: pointer;
            font-weight: bold;
        }
    }

    @media screen and (min-width: 300px) and (max-width:1099px) {
        justify-content: space-between;
        height: 90px;

        .navbar-mobile {
            display: block;

            svg {
                height: 90px;
                padding-left: 1rem;
            }
        }

        .navbar-logo {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 50px;
                height: 55px;
            }
        }

        .navbar-menu {
            display: block;
            margin-left: 2rem;
        }

        .navbar-list {
            display: none;
        }

        .navbar-language {
            margin: 0 1rem 0 0;

            p {
                font-size: 16px;
                margin: 0 0.2rem 0 0;
            }
        }
    }
}