.footer-container {
    background: linear-gradient(180deg, #1B1B1F 0%, #1B1B1F 16.76%, rgba(27, 27, 31, 0) 93.6%);

    .informacion-container-mobile {
        display: none;
    }

    .calendar-b {
        min-width: 124.87px;
    }

    .contacto {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;

        h1 {
            font-size: 42px;
        }

        p {
            font-size: 18px;
            margin: 10px 0;
        }


    }

    .disfruta {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        margin-top: 0rem;

        h1 {
            font-size: 57px;
            width: 795px;
            text-align: center;
            font-weight: 300;
            margin-top: 120px;
        }

        button {
            color: #0667B0;
            background-color: rgba(255, 255, 255, 0.87);
            size: 32px;
            border-radius: 38px;
            border: 2px solid #0667B0;
            padding: 15px 45px;
            font-size: 20px;
            font-weight: 600;
            margin: 1rem 0 4rem;
        }

        img {
            width: 90px;
            margin-bottom: 3rem;

        }

        .disfruta-bstadium {
            display: flex;
            align-items: center;

            p {
                font-size: 20px;
                margin: 0 1rem 3.1rem 0;
            }

            img {
                width: 130px;
            }
        }


    }

    @media screen and (min-width: 300px) and (max-width:859px) {

        .contacto {
            margin-top: 4rem;

            h1 {
                font-size: 40px;
            }

            p {
                font-size: 20px;
                color: white;
                text-align: center;
            }
        }

        .disfruta {

            h1 {
                font-size: 40px;
                width: 325px;
                margin-bottom: 3rem;
                padding-top: 3rem;
            }

            button {
                margin: 3rem 0;
                padding: 13px 50px;
                font-size: 20px;
            }

            img {
                width: 84px;
                padding-bottom: 0.5rem;
            }

            .disfruta-bstadium {

                p {
                    font-size: 18px;
                    margin: 0 0.5rem 3.5rem 0
                }

            }
        }

        p {
            font-size: 16px;
            color: white;
        }

        .informacion-title {
            h1 {
                font-size: 50px;
                padding: 1rem 0 2rem 0;
            }
        }

        .informacion-text {
            display: none;
        }

        .informacion-container {
            padding: 0;
        }

        .informacion-container-mobile {
            display: block;
            padding: 0 15% 0 20%;

            .informacion-container-mobile-general {
                display: flex;
                margin: 1rem 0 0 0rem;
                justify-content: center;
                inline-size: fit-content;

                #id {
                    margin: .3rem 0 0 0;
                }

                p {
                    margin: 0.5rem 0 0 1rem;
                }

                .informacion-p-location {
                    padding-left: 0.4rem;
                }

                .mobile-general-icon-calendar {


                    img {
                        width: 20px;
                        padding: 0.5rem 0 0 0;
                    }

                    p {
                        margin: 0.5rem 0 0 0rem;
                    }
                }

                .mobile-general-text {
                    display: flex;
                }

                .mobile-general-icon-clock {
                    .mobile-general-text {
                        margin-right: 9.7rem;
                    }

                    img {
                        width: 20px;
                        padding: 0.5rem 0 0 0;
                    }
                }

                .mobile-general-icon-location {
                    .mobile-general-text {
                        margin-right: 3.4rem;

                        p {
                            margin: 0 0 0 0.4rem;
                        }
                    }

                    img {
                        width: 15px;
                        padding: 0.5rem 0 0 0;
                    }
                }

                .mobile-general-icon-menor {
                    .mobile-general-text {
                        margin-right: 2.7rem;
                    }

                    img {
                        width: 20px;
                        padding: 0.2rem 0 0 0;
                    }
                }

                .mobile-general-icon-language {
                    .mobile-general-text {
                        margin-right: 1.8rem;
                    }

                    img {
                        width: 20px;
                        padding: 0.7rem 0 0 0;
                    }
                }

                .mobile-general-icon-declined {
                    .mobile-general-text {
                        margin-right: 4rem;
                    }

                    img {
                        width: 20px;
                        padding: 0.5rem 0 0 0;
                    }
                }

                .mobile-general-icon-custom {
                    img {
                        width: 20px;
                        padding: 0.5rem 0 0 0;
                    }
                }
            }
        }
    }
}