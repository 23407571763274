.main-container {

    .main-first {
        width: 100%;
        height: 75vh;
        background-image: url(../../Assets/Images/Desktop/Pasillo-estadio.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: white;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        place-items: flex-end;
        justify-content: center;

        h1 {
            text-align: center;
            color: white;
            font-size: 40px;
            margin: 0;
            padding: 0 0 3rem 0;
            
            font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
            align-self: center;
        }

        p {
            width: 500px;
            font-size: 18px;
            text-align: right;
            padding-right: 10rem;
        }
    }

    .main-second {
        width: 100%;
        height: 75vh;
        color: white;
        background-image: url(../../Assets/Images/Desktop/Levanta-copa.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        place-items: flex-end;

        h1 {
            margin: 0;
            text-align: center;
            color: white;
            font-size: 40px;
            margin: 0;
            padding: 5rem 0 3rem 0;
            font-weight: 600;
            align-self: center;
            font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
        }

        .main-second-text {
            display: flex;

            h2 {
                font-size: 40px;
                margin-left: 1.5rem;
                padding-right: 10rem;

            }

            img {
                width: 45px;
                animation-name: floating;
                animation-duration: 2s;
                animation-timing-function: ease;
                animation-iteration-count: infinite;
            }
        }

        p {
            width: 500px;
            font-size: 18px;
            padding-right: 10rem;
            text-align: right;
        }
    }

    .main-third {
        width: 100%;
        height: 75vh;
        color: white;
        background-image: url(../../Assets/Images/Desktop/Experiencia-inmersiva.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-attachment: fixed;
        justify-content: center;

        .custom-title{
            font-weight: 700;
        }

        h1 {
            margin: 0;
            text-align: left;
            color: white;
            font-size: 40px;
            margin: 0;
            padding: 0 0 3rem 0;
            font-weight: 600;
            font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
        }

        h2 {
            margin: 0;
            text-align: center;
            padding-top: 3rem;
            color: white;
            font-size: 40px;
            margin: 0;        
            font-weight: 600;
            font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
        }


        p {
            width: 500px;
            text-align: center;
            font-size: 18px;
            padding-top: 1rem;
        }

        img {
            width: 70px;
            padding-top: 2rem;
            animation-name: floating;
            animation-duration: 2s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
        }
    }

    .main-fourth {
        width: 100%;
        height: 75vh;
        color: white;
        background-image: url(../../Assets/Images/Desktop/Foto-jugadores2.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        
        background-attachment: fixed;
        justify-content: center;

        .main-fourth-title{
            
            text-align: center;
            

            h1{
                font-size: 40px;            
                font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
                margin: 0;
            }
        }
        .main-fourth-text {
            display: flex;
            padding: 3rem 0 0 10rem;            

            h2 {
                margin: 0;
                font-size: 38px;
                width: 412px;
                font-weight: 600;
            }

            img {
                width: 60px;
                animation-name: floating;
                animation-duration: 2s;
                animation-timing-function: ease;
                animation-iteration-count: infinite;
            }
        }

        p {
            width: 500px;
            font-size: 18px;
            padding: 2rem 0 0 10rem;
        }
        span{
            font-size: 14px;
            padding: 2rem 0 0 10rem;
            width: 500px;
        }
    }

    .main-fiveth {
        width: 100%;
        height: 75vh;
        color: white;
        background-image: url(../../Assets/Images/Desktop/Palco-RS.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-attachment: fixed;
        justify-content: center;

        h1 {
            margin: 0;
            text-align: left;
            color: white;
            font-size: 40px;
            margin: 0;
            
            font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
        }

        p {
            width: 500px;
            text-align: center;
            font-size: 18px;
            padding-top: 1rem;
        }

        img {
            width: 50px;
            padding-top: 2rem;
            animation-name: floating;
            animation-duration: 2s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
        }
    }

    @media screen and (min-width: 300px) and (max-width:859px) {

        .main-first {
            background-image: url(../../Assets/Images/Mobile/Carousel1-Mobile.png);
            background-attachment: unset;
            height: 90vh;
            justify-content: center;

            h1 {
                font-size: 40px;
                
            }

            p {
                font-size: 17px;
                padding: 0 1rem 0 1rem;
                align-self: center;
                text-align: left;
                width: 70%;
            }
        }

        .main-second {
            background-image: url(../../Assets/Images/Mobile/Carousel2-Mobile.png);
            background-attachment: unset;
            height: 90vh;

            h1 {
                font-size: 40px;
                padding: 14rem 2rem 2rem 2rem;
            }

            .main-second-text {
                flex-direction: column-reverse;
                align-self: center;
                align-items: center;



                h2 {
                    font-size: 35px;
                    padding-right: 0rem;
                    align-self: center;
                    margin: 0;
                    padding: 0rem 0 2rem;
                    text-align: center;
                }

                img {
                    width: 40px;
                    padding-bottom: 2rem;
                }

            }

            p {
                font-size: 17px;
                padding: 0;
                align-self: center;
                text-align: left;
                width: 70%;
            }
        }

        .main-third {
            background-image: url(../../Assets/Images/Mobile/Carousel3-Mobile.png);
            background-attachment: initial;
            height: 90vh;

            h1 {
                font-size: 40px;
                width: 80%;
                padding: 0rem 2rem 0 2rem;
                text-align: center;
                margin: 0;
            }

            p {
                font-size: 17px;
                padding: 2rem 0 0 0;
                align-self: center;
                text-align: left;
                width: 70%;
            }

            img {
                width: 50px;
            }

            h2{
                font-size: 35px;
            }
        }

        .main-fourth {
            background-image: url(../../Assets/Images/Mobile/Carousel4-Mobile.png);
            display: flex;
            flex-direction: column;
            align-items: center;
            background-attachment: unset;
            height: 90vh;

            .main-fourth-title{
                padding: 0;                            
    
                h1{
                    font-size: 40px;            
                    
                }
            }

            .main-fourth-text {

                padding: 5rem 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: center;

                h2 {
                    font-size: 35px;
                    padding-bottom: 6rem;
                    width: 90%;
                    margin: 0 2rem 0 2rem;
                    text-align: center;
                }

                img {
                    width: 50px;
                    margin: -4rem 0 -4rem 0;

                }               
                
            }

            p {
                font-size: 17px;
                padding: 6rem 0 0 0;
                align-self: center;
                text-align: left;
                width: 70%;
            }

            span {
                font-size: 14px;
                padding: 1rem 0 0 0;
                align-self: center;
                text-align: left;
                width: 70%;
            }
         
        }

        .main-fiveth {
            background-image: url(../../Assets/Images/Desktop/Palco-RSmobile.jpg);
            background-attachment: initial;
            height: 90vh;

            h1 {
                font-size: 40px;
                width: 80%;
                padding: 0rem 2rem 0 2rem;
                text-align: center;
                margin: 0;
            }

            p {
                font-size: 17px;
                padding: 2rem 0 0 0;
                align-self: center;
                text-align: left;
                width: 70%;
            }

            img {
                width: 40px;
            }
        }

        
    }
}