.informacion-container {
    color: white;
    padding-bottom: 5rem;
    background: linear-gradient(180deg, #1B1B1F 0%, #1B1B1F 16.76%, rgba(27, 27, 31, 0) 93.6%);
    background-size: cover;
    padding: 0;

    .informacion-container-mobile {
        display: none;
    }

    p {
        font-size: 18px;
    }

    .informacion-title {
        h1 {
            text-align: center;
            font-size: 42px;
            margin: 0;
            padding: 1rem 0 4rem 0;
        }
    }

    .informacion-text {
        font-size: 30px;

        .p-text {
            margin-right: 20%;

        }

        .informacion-text-calendar {
            display: flex;

            img {
                margin: 0 1.9rem 2rem 20%;
            }

            p {
                margin: 0 1rem 0rem 0;
            }
        }

        .informacion-text-clock {
            display: flex;

            img {
                margin: 1rem 1.8rem 2rem 20%;
            }

            b {
                margin-right: 1rem;
            }
        }

        .informacion-text-location {
            display: flex;

            img {
                margin: 0 2.5rem 2rem 20%;
            }

            p {
                margin: 0 1rem 0.5rem 0;
            }
        }

        .informacion-text-menor {
            display: flex;

            img {
                margin: 0 2rem 2rem 20%;
            }

            p {
                margin: 0 1rem 0.5rem 0;
            }
        }

        .informacion-text-language {
            display: flex;

            img {
                margin: 0 1.7rem 2rem 20%;
            }

            p {
                margin: 0 1rem 0.5rem 0;
            }
        }

        .informacion-text-declined {
            display: flex;

            img {
                margin: 0 2rem 2rem 20%;
            }

            p {
                margin: 0 1rem 0.5rem 0;
            }
        }

        .informacion-text-custom {
            display: flex;

            img {
                margin: 0 2rem 6rem 20%;
            }

            p {
                margin: 0 1rem 0.5rem -0.2rem;
            }
        }
    }

    @media screen and (min-width: 300px) and (max-width:859px) {

        .informacion-title {
            h1 {
                font-size: 42px;
                padding: 1rem 0 2rem 0;
            }
        }
    }
}