* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  background: linear-gradient(90deg, #1B1B1F 0.82%, #0667B0 27.77%, #0667B0 58.65%, #0667B0 74.33%, #1B1B1F 94.91%);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700;800;900&display=swap');

* {
  font-family: montserrat;
}

@keyframes floating {
  0% {
    transform: translate(0%, 0%) rotate(360deg);
  }

  25% {
    transform: translate(5%, 15%) rotate(360deg);
  }

  50% {
    transform: translate(10%, 5%) rotate(360deg);
  }

  75% {
    transform: translate(0%, 15%) rotate(360deg);
  }

  100% {
    transform: translate(0%, 0%) rotate(360deg);
  }
}