.itinerary {
    color: white;

    .itinerary-tittle {
        h1 {
            text-align: center;
            margin: 0;
            padding: 5rem;
            font-size: 42px;
        }
    }

    .itinerary-container {
        display: flex;
        padding: 0 12rem 4rem 12rem;
        margin: 2rem 0;
        justify-content: center;
        align-items: center;

        .itinerary-img {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        .itinerary-text {
            align-self: auto;
            width: 50%;
            text-align: -webkit-center;

            .itinerary-text-container {
                width: 90%;
                margin-left: 3rem;

                p {
                    text-align: left;
                    margin: .3rem 0;
                }

                .itinerary-text-individual {
                    display: flex;

                    .itinerary-text-individual-number {
                        display: flex;
                        text-align: center;

                        p {
                            height: 22px;
                            width: 22px;
                            background-color: #0161a2;
                            border-radius: 50%;
                            margin-right: 1rem;
                            text-align: center;

                        }

                    }
                }
            }
        }
    }

    @media screen and (min-width: 300px) and (max-width:600px) {

        .itinerary-tittle {
            font-size: 10px;

            h1 {
                padding: 5rem 0 0 0;
            }
        }

        .itinerary-container {
            flex-direction: column-reverse;
            padding: 0 2rem 0 2rem;

            .itinerary-img {
                width: 100%;
                padding-bottom: 3rem;


                img {
                    width: 100%;
                }
            }

            .itinerary-text {
                width: 100%;
                padding-bottom: 5rem;

                .itinerary-text-container {
                    width: 90%
                }
            }
        }
    }
}